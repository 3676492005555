

.Hero {
    // background-color: grey;
    min-height: calc(min(900px, min(100vh - 100px)));
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    font-family: Roboto, sans-serif;

    .Content {
        padding: 0% calc(10% - 50px) 0 calc(10% - 20px);
        height: fit-content;

        .LogoContianer {
            font-size: 28px;
            display: flex;
            align-items: baseline;  
            line-height: 48px;
            .Logo {
                background-image: url(../../../assets/Logo1.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 54px;
                width: 84px;
                margin-right: 10px;
            }
        }
    }

    .Slanted {
        z-index: -1;
        display: flex;
        flex-flow: row;
        place-items: center;
        position: absolute;
        top: calc(max(425px, 60%));
        left: calc(-350px + 15%);
        transform: rotate(-20deg);

        & > div {
            display: flex;
            flex-flow: row;
            place-items: center;
            & > div {
                border-radius: 46px;
                border: 30px solid transparent;
                height: calc(450px + 25vw);
                width: calc(450px + 25vw);
            }
            & > span {
                width: 5vw;
            }
            & > .img1 {
                background: url("../../../assets/sa-content-activity-212.png") 10px -105px padding-box, linear-gradient(to right, #A1F4EA, #72B1E7) border-box;
                // background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            & > .img2 {
                background: url("../../../assets/2x-no-mark.jpg") padding-box, linear-gradient(to left, #A1F4EA, #72B1E7) border-box;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
}
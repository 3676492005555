
.HomeSection1 {
    display: flex;
    flex-flow: column;
    > .Content {
        display: flex;
        margin: 40px 20px;
        > div {
            min-width: calc(350px - 40px);
            min-height: 355px;
        }
        > .Text {
            flex: 6;
            display: flex;
            flex-flow: column;
            justify-content: center;
            
            > .SubTitle {
                font-size: 40px;
                font-weight: 600;
            }
            > p {
                --font-size: 20px;
                font-size: var(--font-size);
                line-height: calc(var(--font-size) * 1.7);
            }
        }
        > .Imgs{
            flex: 5;
            position: relative;
        }
    }

    > .Regular {
        flex-wrap: wrap;
    }

    > .Reverse {
        flex-wrap: wrap-reverse;
        > .Text {
            text-align: right;
        }
    }

    .Img {
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .Img_1 {
        @extend .Img;
        background-image: url("../../../assets/AI_1.svg");
    }

    .Img_2_1 {
        @extend .Img;
        transform: scale(0.95);
        background-image: url("../../../assets/Ellipse1.svg");
    }
    .Img_2_2 {
        @extend .Img;
        transform: scale(0.7);
        background-image: url("../../../assets/computer_girl.svg");
    }
}
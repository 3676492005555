/* autoprefixer grid: autoplace */

.Pricing {
    display: flex;
    flex-flow: column;
    place-items: center;

    > .Title {
        color: #3899EC;
        font-size: 54px;
        font-weight: bold;
        text-align: center;
        padding: 0px 0px 25px 0px;
    }

    > .CardList {
        display: flex;
        padding: 15px;
        flex-wrap: wrap;
        gap: 60px;
        width: 100%;
        justify-content: center;
    }
    
    .Price {
        display: flex;
        place-items: center;
        flex-flow: column;
        color: white;
        overflow: hidden;
        padding: 20px;
    }

    .Price-1 {
        @extend .Price;
        background: linear-gradient(135deg, #7364D2, #D6D6FF);
    }

    .Price-2 {
        @extend .Price;
        background: linear-gradient(135deg, #3F6BF5, #A9E0F3);
    }

    .Price-3 {
        @extend .Price;
        background: linear-gradient(135deg, #489EB1, #7FF8E9);
    }

    .ImgContainer {
        width: 100%;
        height: 33%;
        overflow: hidden;

        .Img {
            // flex: 1;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .Img1 {
            @extend .Img;
            background-image: url("../../../assets/Programmer_girl.svg");
            // transform: scale(0.7)
        }

        .Img2 {
            @extend .Img;
            background-image: url("../../../assets/Programmer_boy.svg");
            transform: scale(1)
        }

        .Img3 {
            @extend .Img;
            background-image: url("../../../assets/Programmer_pro.svg");
            background-position: 8px 2px;
            transform: scale(1.2, 1.3);
            overflow: hidden;
        }
    }

    .Divider {
        width: 100%;
        margin: 10px 0px;
        border-bottom: 1.25px solid white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .SubText {
        font-size: 35px;
        margin: 10px 0;
    }

    .Num {
        align-self: center;
        font-size: 80px;
        font-weight: 600;

        .SubNum {
            font-size: 25px;
            font-weight: 500;
        }
    }

    .Text {
        
    }
}
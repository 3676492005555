@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
@import url(https://fonts.googleapis.com/css?family=Oxygen:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}
        
.Title {
  font-size: 64px;
  font-weight: 500;
  line-height: 64px;
}
.SubTitle {
  font-size: 50px;
  color: #3899EC;
}

p {
  color: #344045;
  color: var(--grey);
  font-size: 24px;
  padding-top: 30px;
  max-width: 52ch;
}

:root {
  --grey: #344045;
  --max-width: 1100px;
}

.Home{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;place-items:center}.Home>.Content{width:100%;margin-bottom:200px;margin-top:45px}.Home>.Content>div{padding:25px 0;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.Home>.Content>div>div{max-width:var(--max-width)}

.Button{background-color:#3899EC;border-radius:8px;color:white;padding:10px 15px;cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none}

.Navbar{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;-webkit-align-items:center;align-items:center;width:100%;height:100px;font-family:Roboto, sans-serif;font-size:17px;font-weight:400;margin:auto;padding:25px 5%;background-color:unset}

.Footer{width:100%}.Footer>.Section1{width:100%;min-height:300px;background-color:#72ADC0}.Footer>.Section2{width:100%;min-height:400px;background-color:#4F7586}

.Hero{min-height:calc(min(900px, min(100vh - 100px)));width:100%;position:relative;overflow:hidden;display:-webkit-flex;display:flex;font-family:Roboto, sans-serif}.Hero .Content{padding:0% calc(10% - 50px) 0 calc(10% - 20px);height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.Hero .Content .LogoContianer{font-size:28px;display:-webkit-flex;display:flex;-webkit-align-items:baseline;align-items:baseline;line-height:48px}.Hero .Content .LogoContianer .Logo{background-image:url(/static/media/Logo1.8ad49d5b.svg);background-repeat:no-repeat;background-position:center;background-size:contain;height:54px;width:84px;margin-right:10px}.Hero .Slanted{z-index:-1;display:-webkit-flex;display:flex;-webkit-flex-flow:row;flex-flow:row;place-items:center;position:absolute;top:calc(max(425px, 60%));left:calc(-350px + 15%);-webkit-transform:rotate(-20deg);transform:rotate(-20deg)}.Hero .Slanted>div{display:-webkit-flex;display:flex;-webkit-flex-flow:row;flex-flow:row;place-items:center}.Hero .Slanted>div>div{border-radius:46px;border:30px solid transparent;height:calc(450px + 25vw);width:calc(450px + 25vw)}.Hero .Slanted>div>span{width:5vw}.Hero .Slanted>div>.img1{background:url(/static/media/sa-content-activity-212.8e4b2aa7.png) 10px -105px padding-box,linear-gradient(to right, #A1F4EA, #72B1E7) border-box;background-repeat:no-repeat;background-size:cover}.Hero .Slanted>div>.img2{background:url(/static/media/2x-no-mark.fd4f8bed.jpg) padding-box,linear-gradient(to left, #A1F4EA, #72B1E7) border-box;background-position:center;background-repeat:no-repeat;background-size:cover}

.Pricing{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;place-items:center}.Pricing>.Title{color:#3899EC;font-size:54px;font-weight:bold;text-align:center;padding:0px 0px 25px 0px}.Pricing>.CardList{display:-webkit-flex;display:flex;padding:15px;-webkit-flex-wrap:wrap;flex-wrap:wrap;grid-gap:60px;gap:60px;width:100%;-webkit-justify-content:center;justify-content:center}.Pricing .Price,.Pricing .Price-1,.Pricing .Price-2,.Pricing .Price-3{display:-webkit-flex;display:flex;place-items:center;-webkit-flex-flow:column;flex-flow:column;color:white;overflow:hidden;padding:20px}.Pricing .Price-1{background:linear-gradient(135deg, #7364D2, #D6D6FF)}.Pricing .Price-2{background:linear-gradient(135deg, #3F6BF5, #A9E0F3)}.Pricing .Price-3{background:linear-gradient(135deg, #489EB1, #7FF8E9)}.Pricing .ImgContainer{width:100%;height:33%;overflow:hidden}.Pricing .ImgContainer .Img,.Pricing .ImgContainer .Img1,.Pricing .ImgContainer .Img2,.Pricing .ImgContainer .Img3{width:100%;height:100%;background-size:contain;background-repeat:no-repeat;background-position:center}.Pricing .ImgContainer .Img1{background-image:url(/static/media/Programmer_girl.573144ed.svg)}.Pricing .ImgContainer .Img2{background-image:url(/static/media/Programmer_boy.c3721425.svg);-webkit-transform:scale(1);transform:scale(1)}.Pricing .ImgContainer .Img3{background-image:url(/static/media/Programmer_pro.686a057c.svg);background-position:8px 2px;-webkit-transform:scale(1.2, 1.3);transform:scale(1.2, 1.3);overflow:hidden}.Pricing .Divider{width:100%;margin:10px 0px;border-bottom:1.25px solid white;box-shadow:0px 4px 4px rgba(0,0,0,0.25)}.Pricing .SubText{font-size:35px;margin:10px 0}.Pricing .Num{-webkit-align-self:center;align-self:center;font-size:80px;font-weight:600}.Pricing .Num .SubNum{font-size:25px;font-weight:500}

.Card{border-radius:15px;width:260px;height:445px;box-shadow:0px 3px 30px rgba(0,0,0,0.15)}

.HomeSection1{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column}.HomeSection1>.Content{display:-webkit-flex;display:flex;margin:40px 20px}.HomeSection1>.Content>div{min-width:calc(350px - 40px);min-height:355px}.HomeSection1>.Content>.Text{-webkit-flex:6 1;flex:6 1;display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;-webkit-justify-content:center;justify-content:center}.HomeSection1>.Content>.Text>.SubTitle{font-size:40px;font-weight:600}.HomeSection1>.Content>.Text>p{--font-size: 20px;font-size:var(--font-size);line-height:calc(var(--font-size) * 1.7)}.HomeSection1>.Content>.Imgs{-webkit-flex:5 1;flex:5 1;position:relative}.HomeSection1>.Regular{-webkit-flex-wrap:wrap;flex-wrap:wrap}.HomeSection1>.Reverse{-webkit-flex-wrap:wrap-reverse;flex-wrap:wrap-reverse}.HomeSection1>.Reverse>.Text{text-align:right}.HomeSection1 .Img,.HomeSection1 .Img_1,.HomeSection1 .Img_2_1,.HomeSection1 .Img_2_2{width:100%;height:100%;position:absolute;background-size:contain;background-repeat:no-repeat;background-position:center}.HomeSection1 .Img_1{background-image:url(/static/media/AI_1.43b7ab75.svg)}.HomeSection1 .Img_2_1{-webkit-transform:scale(0.95);transform:scale(0.95);background-image:url(/static/media/Ellipse1.95569e15.svg)}.HomeSection1 .Img_2_2{-webkit-transform:scale(0.7);transform:scale(0.7);background-image:url(/static/media/computer_girl.d04ac39a.svg)}

.Agents{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;place-items:center}.Agents .Title{color:#3899EC;font-size:54px;font-weight:bold;text-align:center;padding:0px 0px 25px 0px}.Agents .AgentList{display:-webkit-flex;display:flex;padding:15px;-webkit-flex-wrap:wrap;flex-wrap:wrap;grid-gap:60px;gap:60px;width:100%;-webkit-justify-content:center;justify-content:center}.Agents .AgentList .Agent-1{background:linear-gradient(135deg, #7364D2, #D6D6FF)}.Agents .AgentList .Agent-2{background:linear-gradient(135deg, #3F6BF5, #A9E0F3)}.Agents .AgentList .Agent-3{background:linear-gradient(135deg, #489EB1, #7FF8E9)}

.HomeSection2{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column}.HomeSection2>.Content{display:-webkit-flex;display:flex;margin:40px 20px}.HomeSection2>.Content>div{min-width:calc(350px - 40px);min-height:355px}.HomeSection2>.Content>.Text{-webkit-flex:6 1;flex:6 1;display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;-webkit-justify-content:center;justify-content:center}.HomeSection2>.Content>.Text>.SubTitle{font-size:40px;font-weight:600}.HomeSection2>.Content>.Text>p{--font-size: 20px;font-size:var(--font-size);line-height:calc(var(--font-size) * 1.7)}.HomeSection2>.Content>.Imgs{-webkit-flex:5 1;flex:5 1;position:relative}.HomeSection2>.Regular{-webkit-flex-wrap:wrap;flex-wrap:wrap}.HomeSection2>.Reverse{-webkit-flex-wrap:wrap-reverse;flex-wrap:wrap-reverse}.HomeSection2>.Reverse>.Text{text-align:right}.HomeSection2 .Img,.HomeSection2 .Img_1_1,.HomeSection2 .Img_1_2,.HomeSection2 .Img_2_1,.HomeSection2 .Img_2_2{width:100%;height:100%;position:absolute;background-size:contain;background-repeat:no-repeat;background-position:center}.HomeSection2 .Img_1_1{background-image:url(/static/media/Frame.7c856a1c.svg);background-position:55% 0%}.HomeSection2 .Img_1_2{-webkit-transform:scale(0.6, 0.7);transform:scale(0.6, 0.7);background-image:url(/static/media/Computers.077d9c46.svg);background-position:50% 0%}.HomeSection2 .Img_2_1{background-image:url(/static/media/Ellipse1.95569e15.svg)}.HomeSection2 .Img_2_2{-webkit-transform:scale(0.7);transform:scale(0.7);background-image:url(/static/media/computer_girl.d04ac39a.svg)}




.Navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* max-width: var(--body-maxlength); */
    width: 100%;
    height: 100px;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 400;

    margin: auto;
    padding: 25px 5%;

    background-color: unset;

}
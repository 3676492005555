
.Home {
    display: flex;
    flex-flow: column;
    place-items: center;
    > .Content {
        width: 100%;
        margin-bottom: 200px;
        margin-top: 45px;
        > div {
            padding: 25px 0;
            display: flex;
            justify-content: center;
            > div {
                max-width: var(--max-width);
            }
        }
    }

}


.Agents {
    display: flex;
    flex-flow: column;
    place-items: center;

    .Title {
        color: #3899EC;
        font-size: 54px;
        font-weight: bold;
        text-align: center;
        padding: 0px 0px 25px 0px;
    }

    .AgentList {
        display: flex;
        padding: 15px;
        flex-wrap: wrap;
        gap: 60px;
        width: 100%;
        
        justify-content: center;

        .Agent-1 {
            background: linear-gradient(135deg, #7364D2, #D6D6FF);
        }

        .Agent-2 {
            background: linear-gradient(135deg, #3F6BF5, #A9E0F3);
        }

        .Agent-3 {
            background: linear-gradient(135deg, #489EB1, #7FF8E9);
        }
    }
}

.Footer {
    width: 100%;
    > .Section1 {
        width: 100%;
        min-height: 300px;
        background-color: #72ADC0;
    }
    > .Section2 {
        width: 100%;
        min-height: 400px;
        background-color: #4F7586;
    }
}